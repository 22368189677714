import styled from "styled-components";

interface StyledSidenavProps {
  position?: "left" | "right";
  open: boolean;
  width?: number;
  scroll?: boolean;
}

export const StyledSidenav = styled.div<StyledSidenavProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 990;
  background:  rgba(171, 171, 171, 0.9);

  @keyframes slide {
    from {
      ${({ position }) => position}: -100%;
    }
    to {
      ${({ position }) => position}: 0;
    }
  }

  .sidenav-content {
    position: absolute;
    ${({ position }) => position}: 0;
    width: 100%;
    height: 100%;
    overflow: ${({ scroll }) => (scroll ? "auto" : "hidden")};
    animation: slide 250ms linear;
  }

  & ~ .cursor-pointer {
    cursor: pointer;
  }
`;
