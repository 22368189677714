import Icon from "@component/icon/Icon";
import Image from "@component/Image";
import NavbarMobile from "@component/navbar-mobile/NavbarMobile";
import Navbar from "@component/navbar/Navbar";
import Sidenav from "@component/sidenav/Sidenav";
import Link from "next/link";
import React, { useState } from "react";
import Container from "../Container";
import FlexBox from "../FlexBox";
import StyledHeader from "./HeaderStyle";

type HeaderProps = {
  isFixed?: boolean;
  className?: string;
  setting: any;
};

const Header: React.FC<HeaderProps> = ({ className, setting }) => {
  const [open, setOpen] = useState(false);
  const toggleSidenav = () => setOpen(!open);

  const logo = setting?.filter(
    (prop) => prop.attributes.Name === "site.logo"
  )[0];

  return (
    <StyledHeader className={className}>
      <Container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="100%"
      >
        <FlexBox className="logo" alignItems="start" mr="1rem">
          <Link href="/">
            <Image
              src={logo?.attributes.Media.data.attributes.url}
              height={42}
              alt="logo"
            />
          </Link>
        </FlexBox>

        <FlexBox justifyContent="end" flex="1 1 0">
          <Navbar navListOpen={true} />

          <FlexBox className="sidenav-mobile">
            <Sidenav
              handle={
                open ? (
                  <></>
                ) : (
                  <Icon defaultColor="currentColor" mr="0.5rem">
                    menu
                  </Icon>
                )
              }
              position="right"
              open={open}
              width={380}
              toggleSidenav={toggleSidenav}
            >
              <NavbarMobile toggleSidenav={toggleSidenav} />
            </Sidenav>
          </FlexBox>
        </FlexBox>
      </Container>
    </StyledHeader>
  );
};

export default Header;
