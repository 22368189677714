import Badge from "@component/badge/Badge";
import Container from "@component/Container";
import FlexBox from "@component/FlexBox";
import Icon from "@component/icon/Icon";
import NavLink from "@component/nav-link/NavLink";
import { H4 } from "@component/Typography";
import navbarNavigations from "@data/navbarNavigations";

const NavbarMobile = ({ toggleSidenav }) => {
  return (
    <Container display="flex" height="100%">
      <FlexBox position="absolute" top="28px" right="0" left="90%" bottom="0">
        <Icon
          defaultColor="currentColor"
          mr="0.5rem"
          onClick={toggleSidenav}
          className="cursor-pointer"
        >
          close
        </Icon>
      </FlexBox>
      <FlexBox
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        opacity="1"
        margin="0 auto"
      >
        {navbarNavigations?.map((nav: any) => {
          return (
            <NavLink
              className="nav-link ext-link"
              href={nav.url}
              key={nav.title}
              paddingY="10px"
              onClick={toggleSidenav}
            >
              {nav.badge ? (
                <Badge style={{ marginRight: "0px" }} title={nav.badge}>
                  {nav.title}
                </Badge>
              ) : (
                <H4
                  className={`nav-link text-uppercase ${
                    nav.icon ? "nav-icon" : ""
                  }`}
                >
                  {nav.title}
                </H4>
              )}
            </NavLink>
          );
        })}
      </FlexBox>
    </Container>
  );
};

export default NavbarMobile;
