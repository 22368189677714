import Image from "@component/Image";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { getTheme } from "../../utils/utils";
import Box from "../Box";
import Container from "../Container";
import FlexBox from "../FlexBox";
import Grid from "../grid/Grid";
import Icon from "../icon/Icon";
import Typography, { Paragraph } from "../Typography";

const StyledLink = styled.a`
  position: relative;
  display: block;
  padding: 0.3rem 0rem;
  color: ${getTheme("colors.gray.500")};
  cursor: pointer;
  border-radius: 4px;
  :hover {
    color: ${getTheme("colors.gray.100")};
  }
`;

const Footer = ({ setting }) => {
  const logo = setting?.filter(
    (prop) => prop.attributes.Name === "site.logo"
  )[0];

  const description = setting?.filter(
    (prop) => prop.attributes.Name === "site.description"
  )[0];

  const address = setting?.filter(
    (prop) => prop.attributes.Name === "site.address"
  )[0];

  const map = setting?.filter((prop) => prop.attributes.Name === "site.map")[0];

  const email = setting?.filter(
    (prop) => prop.attributes.Name === "site.email"
  )[0];

  const phone = setting?.filter(
    (prop) => prop.attributes.Name === "site.phone"
  )[0];

  const facebook = setting?.filter(
    (prop) => prop.attributes.Name === "site.social.facebook"
  )[0];

  const instagram = setting?.filter(
    (prop) => prop.attributes.Name === "site.social.instagram"
  )[0];

  const iconList = [
    { iconName: "facebook", url: facebook?.attributes.Value },

    { iconName: "instagram", url: instagram?.attributes.Value },
  ];

  const companyLinks = [
    {
      title: "About Us",
      url: "/about-us/",
    },
    {
      title: "Services",
      url: "/services/",
    },
    {
      title: "Team",
      url: "/team/",
    },
    {
      title: "Contact",
      url: "/contact/",
    },
  ];

  return (
    <footer>
      <Box bg="#262626">
        <Container p="1rem" color="white">
          <Box py="5rem" overflow="hidden">
            <Grid container spacing={6}>
              <Grid item lg={6} md={6} xs={12}>
                <Link href="/">
                  <Image
                    mb="1.25rem"
                    src={logo?.attributes.Media.data.attributes.url}
                    height={42}
                    alt="logo"
                  />
                </Link>

                <Paragraph mb="1.25rem" color="gray.500">
                  {description?.attributes.Value}
                </Paragraph>
              </Grid>

              <Grid item lg={3} md={6} xs={12}>
                <Typography
                  fontSize="25px"
                  fontWeight="600"
                  mb="1.25rem"
                  lineHeight="1"
                >
                  COMPANY
                </Typography>

                <div>
                  {companyLinks.map((item, ind) => (
                    <Link href={item.url} key={ind}>
                      <StyledLink>{item.title}</StyledLink>
                    </Link>
                  ))}
                </div>
              </Grid>

              <Grid item lg={3} md={6} xs={12}>
                <Typography
                  fontSize="25px"
                  fontWeight="600"
                  mb="1.25rem"
                  lineHeight="1"
                >
                  Contact Us
                </Typography>
                <Typography py="0.3rem" color="gray.500">
                  <StyledLink href={map?.attributes.Value} target="_blank">
                    {address?.attributes.Value}
                  </StyledLink>
                </Typography>
                <Typography py="0.3rem" color="gray.500">
                  <StyledLink href={`mailto:${email?.attributes.Value}`}>
                    {email?.attributes.Value}
                  </StyledLink>
                </Typography>
                <Typography py="0.3rem" mb="1rem" color="gray.500">
                  <StyledLink href={`tel:${phone?.attributes.Value}`}>
                    {phone?.attributes.Value}
                  </StyledLink>
                </Typography>

                <FlexBox className="flex" mx="-5px">
                  {iconList.map((item) => (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer noopenner"
                      key={item.iconName}
                    >
                      <Box
                        m="5px"
                        size="small"
                        p="10px"
                        bg="#434549"
                        borderRadius="50%"
                      >
                        <Icon size="12px" defaultColor="auto">
                          {item.iconName}
                        </Icon>
                      </Box>
                    </a>
                  ))}
                </FlexBox>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
