const navbarNavigations = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Projects",
    url: "/all-projects/",
    child: [
      {
        title: "Multifamily",
        url: "/multifamily/",
        child: [
          {
            title: "High-Rise Buildings",
            url: "/multifamily/high-rise-buildings/",
          },
          {
            title: "Low to Mid-Rise Buldings",
            url: "/multifamily/low-to-mid-rise-buildings/",
          },
        ],
      },
      {
        title: "Residential",
        url: "/residential/",
        child: [
          {
            title: "Town-homes",
            url: "/residential/townhomes/",
          },
          {
            title: "Private Homes",
            url: "/residential/custom-homes/",
          },
        ],
      },
      {
        title: "Commercial",
        url: "/commercial",
      },
      {
        title: "Master Planning",
        url: "/master-planning",
      },
    ],
  },
  {
    title: "About Us",
    url: "/about-us/",
  },
  {
    title: "Services",
    url: "/services/",
  },
  {
    title: "Team",
    url: "/team/",
  },
  {
    title: "Contact",
    url: "/contact/",
  },
  {
    title: "Call Us",
    url: "tel:305-669-5040",
    extLink: true,
    icon: "phone-call"
  },
];

export default navbarNavigations;
