import Footer from "@component/footer/Footer";
import Header from "@component/header/Header";
import Sticky from "@component/sticky/Sticky";
import Head from "next/head";
import React from "react";
import StyledAppLayout from "./AppLayoutStyle";

const AppLayout = ({ children, navbar, title, setting }: any) => {
  const titleWeb = setting?.filter(
    (prop) => prop.attributes.Name === "site.title"
  )[0];

  title = title
    ? titleWeb
      ? `${title} - ${titleWeb.attributes.Value}`
      : title
    : titleWeb
    ? titleWeb.attributes.Value
    : ``;

  return (
    <StyledAppLayout>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Lato&display=swap"
          rel="stylesheet"
        />
      </Head>

      <Sticky fixedOn={0}>
        <Header setting={setting} />
      </Sticky>

      {navbar && <div className="section-after-sticky">{navbar}</div>}
      {!navbar ? (
        <div className="section-after-sticky">{children}</div>
      ) : (
        children
      )}

      <Footer setting={setting} />
    </StyledAppLayout>
  );
};

export default AppLayout;
